.containerItemCart {
	width: 100%;
	/* max-height: 99vh; */
	display: flex;
	justify-content: space-between;
	border-bottom: 0.2px solid #ddd9d9;
}
.imgCartItem {
	width: 85px;
	height: 85px;
	object-fit: cover;
}
.containerDatosCart {
	width: 100%;
}
.tituloYPrecio {
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
	
}

.preciocart{
	color: #23563f !important;
	font: weight 400px !important;
}
.tituloYPrecio > h3 {
	width: 80%;
	font-size: 1.2rem;
}
.imputCantidad {
	border-bottom: none;
	border-top: none;
	border-left: none;
	border-right: none;
	text-align: center;

	width: 30px!important;
	height: 30px!important;
}
.contenedorBotones > button {
	display: flex;
	align-items: center;
}

.mas {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px!important;
	border:none!important;
	width: 30px!important;
	/* border-radius: 0px 50px 50px 0; */
	/* background-color: var(--color-rosa-claro); */
}
button:focus {
	box-shadow: none !important;
}
button:active {
	box-shadow: none !important;
}
button:after {
	box-shadow: none !important;
}
.menos {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px!important;
	width: 30px!important;
	border:none!important
	/* border-radius: 50px 0px 0px 50px; */
	/* background-color: var(--color-rosa-claro); */
}
.contenedorBotones {
	display: flex;
	align-items: center;
	margin-top: -35px;
	padding-left: 10px;
}
.subtotal {
	display: flex;
	justify-content: flex-end;
}
