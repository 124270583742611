header {
	width: 100%;
	height: 5vh;
	min-height: 5vh;
	position: fixed;
	z-index: 2;
}
.mainSales {
	margin-top: 0;
	min-height: 92vh;
	text-align: center;
	overflow-y: hidden;
}
.containerCatalogueSale {
	display: flex;
	flex-wrap: wrap;
	/* margin-left: 20vw; */
	justify-content: flex-start;
	margin-top: -10vh;
	padding-bottom: 5vh;
	margin-left: 25vw;
	width: 80vw;
}

.fondoSaleButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;
	top: 20vh;
	left: 5%;
	background-color: var(--color-verde);
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}

.fondoSaleButton:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
