.fondoCreate {
	background-image: url('../../assets/fondos/FONDO1.png');
}

.noobligatorios {
	/* display: none; */
	background-image: url('../../assets/images/TOAST-FONDO.webp');
	border-radius: 10px;
	box-shadow: 8px 9px 5px 0px rgba(0, 0, 0, 0.18);
	-webkit-box-shadow: 8px 9px 5px 0px rgba(0, 0, 0, 0.18);
	-moz-box-shadow: 8px 9px 5px 0px rgba(0, 0, 0, 0.18);
	-webkit-animation: slide-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
	0% {
		-webkit-transform: translateX(100px);
		transform: translateX(100px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-left {
	0% {
		-webkit-transform: translateX(100px);
		transform: translateX(100px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.containerCreate {
	max-width: 50vw;
	margin-top: 4vh;
}

.obligatorios {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	transition: all ease-in 1s;
}

.inputtt {
	width: 100%;
	transition: all ease-in 1s;
}
.textoCamposObligatorios {
	width: 100%;
	margin-top: 0;
	font-size: 1rem;
	text-align: left;
	font-weight: normal;
	color: var(--color-verde);
}
.h4CreateAccount {
	font-size: 1.2rem;
}

@media (max-width: 550px) {
	.containerCreate {
		max-width: 100%;
		width: 100%;
	}
	.displayResponsive {
		display: flex;
		flex-direction: column;
	}
}
