.selected {
	background-color: var(--color-rosa-claro) !important; /* Cambia esto al color deseado */
}



/* Estilos para móvil */
@media (max-width: 767px) {
  .usersTable th,
  .usersTable td {
    width: 400px !important; /* O ajusta el ancho según tus necesidades */
  }
}