.contenedorSellos {
	display: flex;
border: none !important;
	justify-content: center !important;
}
.testimonios__img > h3 {
	font-size: 1rem;
	font-family: Montserrat;
	font-weight: 600;
}
.stamp__img {
	/* background-color: var(--color-verde-footer); */
	width: 120px;
	height: 120px;
	fill: beige;
}

@media (max-width: 550px) {
	.stamp__img {
		/* background-color: var(--color-verde-footer); */
		width: 100px;
		height: 100px;
	}
}
