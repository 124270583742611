header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
main {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
}

.fondoCartDetail {
	background-image: url('../../assets/fondos/FONDO1.png');
}

.containerCartDetail {
	/* padding-top: 12vh !important; */
	padding-bottom: 3vh !important;
	/* min-height: 92vh; */
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	width: 100%;
}
.jeje {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
}
.containerIconTituloCartDetail {
	display: flex;
	align-items: flex-end;
	background-color: var(--color-verde);
	border-radius: 10px 10px 0 0;
}
.containerIconTituloCartDetail > h4 {
	color: var(--text-color);
}
.containerSectionCartDetail {
	display: flex;
	width: 60vw;
}
.containerSection1,
.containerSection2 {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.containerSection2 {
	align-items: center;
}
.sectionItemCart {
	width: 100%;
	max-height: 30vh;
	overflow-y: scroll;
}
.seguirComprando {
	width: 100%;
	color: var(--color-verde);
	text-align: left;
}
.seguirComprando:hover {
	font-weight: bold;
	cursor: pointer;
}
.sectionResumenCart {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	width: 100%;
	height: 30vh;
}
.sectionResumenCart > h4 {
	font-size: 1.2rem;
}
.sectionResumenCart > h3 {
	font-size: 1.5rem;
}
.botonFinalizarCompra {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-rosa-claro);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	border: none;
	color: var(--text-color);
	font-weight: 600;
	padding: 6px 12px;
	border-radius: 10px;
	font-size: 1.2rem;
	width: 50%;
}
.botonFinalizarCompra:hover {
	background-color: #e9bcbf;
}
@media (max-width: 550px) {
	.containerCartDetail {
		padding-top: 17vh !important;
	}
	.containerSectionCartDetail {
		flex-direction: column;
		width: 90vw;
	}
	.sectionResumenCart {
		height: 20vh;
	}
}
