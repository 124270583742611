.containAbout {
	display: flex;
	justify-content: center;
	/* max-height: 50vh; */
	
}
.abountCont{
	--mask: radial-gradient(78.1px at 50% calc(100% - 110px), #000 99%, #0000 101%) calc(50% - 100px)
	0/200px 100%,
radial-gradient(78.1px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50% calc(100% - 50px) /
	200px 100% repeat-x;
-webkit-mask: var(--mask);
mask: var(--mask);
}
.img > img {
	width: 100%;

	object-fit: contain;
	/* -webkit-mask-image: url("../../assets/images/heart-black-shape.svg");
	mask-image: url("../../assets/images/heart-black-shape.svg");
	-webkit-mask-size: contain;
	mask-size: contain;
	mask-repeat: no-repeat; */
}

.edit-icon-about {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 15%;
	right: 15%;
	background-color: var(--color-verde-claro);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-about:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.edit-icon-aboutText {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin: 2px 2px;
	background-color: var(--color-verde);
	color: var(--text-color);
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-aboutText:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.guardarDesc {
	margin: 5px 0 5px 0;
	background-color: var(--color-verde);
	color: var(--text-color);
}
.guardarDesc:hover {
	color: var(--text-color);
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.img {
	width: 50%;
	position: relative;
}

.text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 5vw;
	width: 50%;

}
.text > p {
	max-width: 75ch;
	text-align: left;
	font-weight: 600 !important;
	font-family: Montserrat;
}
.aboutLik {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}
.aboutLik > button {
	display: flex;
	align-items: center;
	padding: 6px 12px;
	border: none;
	border-radius: 10px;
	font-size: 1.2rem;
	/* font-weight: 600; */
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
	font-family: Montserrat;
	font-weight: lighter;
}
.aboutLik > button:hover {
	background-color: #e9bcbf;
}

@media (max-width: 550px) {
	.containAbout {
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* max-height: 50vh; */
	}
	.img {
		width: 100%;
	}

	.text {
		display: flex;
		height: 100%;
		width: 100%;	
	}
}
