.headerNewPurchase {
	width: 75vw !important;

	background-color: var(--color-rosa-claro);
	margin-left: -25vw !important;
}

.bodyNewPurchase {
	border-radius: 0 0 10px 10px;
	width: 75vw;
	margin-left: -25vw !important;
}
.precios {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.precios > select {
	width: 100%;
	font-size: 0.8rem;
}
.producto-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: start;
	border-bottom: 1px solid #ccc;
}

.canti {
  display: flex;
  justify-content: center !important;
  text-align: center; /* No necesita comillas */
  appearance: textfield !important; /* No necesita comillas */
  -moz-appearance: textfield !important; /* Mozilla Firefox */
  -webkit-appearance: none !important; /* Webkit browsers (Chrome, Safari) */
}
.producto-info > span {
	width: 30%;
	font-size: 1rem;
}
.aundebes{
	height: 45px !important;
}
.acciones {
	width: 20%;
	display: flex;
	align-items: center;
}
.stock {
	display: flex;
	text-align: center;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	font-size: 0.8rem;
}
.costoYdiamantes {
	padding-top: 2vh;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid gray;
}

.mediosPagoDrop .dropdown-menu {
	left: auto;
	right: 0;

	background-color: var(--text-color-navbar);
}

.mediosPagoDrop .dropdown-menu.show {
	padding-left: 5%;
}
.select-red-background {
	background-color: rgba(255, 0, 0, 0.697);
	color: white;
	text-decoration: underline;
}
.pRed {
	color: red;
	text-align: center;
	
}

.productosSeleccionados {
	max-height: 25vh;
	overflow-y: scroll;
	background-color: white !important;
}
.dropNew {
	margin: 0 !important;
}
.mediosDePagoNew {
	padding-top: 2vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.filterOptionXnew {
	color: red;
}
@media (max-width: 1000px) {
	.producto-info {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.producto-info > span {
		width: 80%;
		font-size: 0.8rem;
	}
	.stock {
		justify-content: center;
	}
	.acciones {
		width: 80%;
		text-align: center;
		align-items: center;
	}
	.headerNewPurchase {
		width: 100% !important;
		left: 0;
		margin-left: 0 !important;
	}
	.bodyNewPurchase {
		width: 100%;
		margin-left: 0 !important;
	}
}

@media (max-width: 550px) {
	.producto-info {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.producto-info > span {
		width: 80%;
		font-size: 0.8rem;
	}
	.acciones {
		width: 80%;
	}
	label {
		font-size: 0.8rem;
	}
	.headerNewPurchase {
		width: 100% !important;
		left: 0;
		margin-left: 0 !important;
	}
	.bodyNewPurchase {
		width: 100%;
		margin-left: 0 !important;
	}
}
