.videoContainer {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 2;
	margin-right: 6vw;
	margin-bottom: 10vh;
}

.circular {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 5px solid #fff !important;
	background-color: var(--color-verde);
}
.circular > button {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 2vw;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border: none;
	background-color: #fff;
}
.circular > button:hover {
	cursor: pointer;
	color: var(--text-color-claro);
	background-color: gainsboro;
}
.edit-icon-video {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 2vw;
	border-radius: 50%;
	width: 25px !important;
	height: 25px !important;
	border: none;
	background-color: var(--color-verde) !important;
}
.circular > div {
	/* margin-top: -85px; */
	width: 1000px !important;
	height: 1000px !important;
}

.circular video {
	width: 150%;
	height: 1500%;
	object-fit: contain;
	object-position: center;
}

.saveVideo {
	display: flex;
	justify-content: center !important;
	position: absolute;
	left: 4vw !important;
	border-radius: 4px !important;
	top: 15vh !important;
	width: 65px !important;
	height: 20px;
	border: none;
	background-color: #fff;
}

@media (max-width: 550px) {
	.circular {
		width: 150px;
		height: 150px;
		background-color: var(--color-verde);
	}

	.saveVideo {
		display: flex;
		justify-content: center !important;
		position: absolute;
		left: 10.5vw !important;
		border-radius: 4px !important;
		top: 15vh !important;
		width: 65px !important;
		height: 20px;
		border: none;
		color: #fff !important;
		background-color: var(--color-verde) !important;
	}
}
