.headerMarket {
	top: 0;
	width: 100%;
	max-width: 100vw;
/* height: 3vh !important; */
	position: fixed;
	z-index: 12;
}

.mainMarket {
max-width: 100vw;

	min-height: 100vh;
	text-align: center;
	overflow-y: hidden;
	overflow-x: hidden;

}
/* .fondoHome {
	background-image: url('../../assets/fondos/FONDO1.png');
} */
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.text-enter {

  animation: slideInFromRight 1s forwards;
}

.text-exit {
  animation: slideOutToLeft 2s forwards;
}
.sectionPromotion2{
	margin-top: 10vh;
	margin-bottom: 15vh;
}

.sectionPromotion{
	margin-top: 10vh;
	margin-bottom: 15vh;
}


.sectionCarouselMarket {
	width: 100vw;
	height: 100vh !important;
}
.sectionStampMarket {
	margin-top: 10vh;
border:none !important;
	display: flex;
	align-items: center;
	background-color: var(--color-rosa-trans);
}

.sectionAboutMarket {
	background-color:#20563f;

	margin-bottom:15vh;
	margin-top:15vh
}

.fondohome {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;
	bottom: 0;
	left: 5%;
	background-color: var(--color-verde-footer)!important;
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}


.fondohome:hover {
	background-color: var(--color-verde)!important;
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

@media (max-width: 550px) {
	main {
		margin-top: 10vh;
	}
	.sectionCarousel {
		top: -10vh;
		width: 100%;
		height: 55vh;
	}
	.fondohome {
		top: 20vh;
	}

}
