header {
	width: 100%;
	height: 17vh;
}

.containerRecuperoContraseña {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
}
main > h2 {
	margin-top: 8vh;
	font-size: 2rem;
}

main > h4 {
	font-size: 1rem;
}
.fondoRecuperoContraseña {
	background-image: url('../../assets/fondos/FONDO1.png');
	height: 100vh;
}
.containerRecuperoContraseña {
	margin-top: 0;
	padding-top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.buttonRecuperoContraseña {
	width: 100%;
	display: flex;
	justify-content: center;
	text-decoration: none;
}
.buttonRecuperoContraseña > button {
	background-color: var(--color-rosa-claro);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	border: none;
	display: flex;
	align-items: center;
	color: var(--text-color);
	font-weight: 600;
	padding: 6px 12px;
	border-radius: 10px;
	font-size: 1.2rem;
}

.buttonRecuperoContraseña > button:hover {
	background-color: #e9bcbf;
}
