.detailCarouselCartilla {
  width: 100%;
	height: 300px;
	object-fit: cover;
	z-index: 0;
}

.carousel-container-cartilla{
 height: 100%;
}

.detailImage {
  width: 99%;
  align-self: flex-start;
  justify-self: flex-start;
  max-height: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 5px;
  /* filter: grayscale(100%); Convertir la imagen a escala de grises para crear el efecto de desenfoque */
  /* clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100% ); Recortar la imagen para que solo el borde derecho esté afectado */
}


@media (max-width: 760px) {
  .carousel-container-cartilla{
    height: 50%;
   }

   .detailImage {
 
    clip-path: none
  }
   
}