header {
	width: 100%;
	height: 17vh;
}

main {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
}

main > h4 {
	font-size: 1rem;
}
.fondoError {
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url('../../assets/fondos/FONDO1.png');
	height: 100vh;
}
.containerError {
	height: 70vh !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-top: 8vh;
	overflow-y: hidden !important;
}
.containerError > h2 {
	margin-top: 2vh;
	font-size: 2rem;
}

.containerCorazon {
	margin-top: 5vh;
	background-color: var(--color-rosa-claro);
	width: 22vw;
	border-radius: 50%;
}
.containerCorazon > img {
	width: 100%;
}
.link {
	text-decoration: none !important;
}
h4 {
	color: var(--text-color-claro);
}
@media (max-width: 768px) {
	/* Ajusta el estilo del contenedor principal */
	.containerCorazon {
		width: 60vw;
	}
	.containerError {
		width: 85vw;
	}
	h4 {
		color: var(--text-color-claro);
	}
}
