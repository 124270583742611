.headerGift {
	width: 65vw !important;
	background-color: var(--color-rosa-claro);
	margin-left:-15vw!important
}

.bodyGift {
	border-radius: 0 0 10px 10px;
	width: 65vw !important;
	margin-left:-15vw!important
}

@media (max-width: 550px) {
	.headerGift {
		width: 100vw;
		left: 0;
	}
	.bodyGift {
		width: 100vw;
	}
}
