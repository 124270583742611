.mainStats {
	margin-top: 5vh;
	height: 88vh;
}
.fondoStats {
	background-image: url('../../assets/fondos/FONDO1.png');
}

.picker {
	width: 150px;
}
.stat.py-5.grafico {
	max-height: 50vh;
}
.stat {
	background-color: var(--color-rosa-trans);
}

.statPie {
	background-color: var(--color-rosa-trans);
	max-height: 50vh;
}
.containerDateStats {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	text-align: center;
	align-items: center;
}

.usuariosConCompras {
	background-color: var(--color-rosa-trans);
	height: 20vh;
	overflow-y: scroll;
	overflow-x: hidden;
}
/* En tu archivo CSS o en línea en tu JSX */
@media (max-width: 768px) {
	.stat.py-5.grafico {
		max-height: 50vh; /* Ajusta esta altura máxima según tus necesidades */
	}
}
