.containerModalHistorial {
	width: 100%;
}
.modal-content {
	border: none;
	/* left: 50%; */
}

.headerModalHistorial {
	background-color: var(--color-rosa-trans);
	color: var(--text-color-verde);
	top: 0;
}
.tituloHistorial {
	text-align: left;
	font-size: 1.2rem;
}
.textoHistorialH3Fecha {
	border-bottom: 0.2px solid #ddd9d9;
	font-size: 1.5rem;
	margin-bottom: 10px;
}
.containerCantidadPuntos {
	display: flex;
	justify-content: space-between;
}
.subtotalHistorial {
	border-bottom: 0.2px solid #ddd9d9;
}
.containerCantidadPuntos > h3 {
	font-size: 1.2rem;
}
.textoHistorialH3 {
	font-size: 1.5rem;
}
.textoHistorialH3ConLinea {
	font-size: 1.5rem;

	border-top: 0.2px solid #ddd9d9;
	margin-top: 10px;
}
.containerParaScroll {
	height: 30vh;
	overflow-y: scroll;
}
