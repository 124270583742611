.headerCatalogue {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 14;
}
main {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
}
.dropCatalogue {
	z-index: 2 !important;
}

.strongDrop {
	margin-left: -15px;
	width: 105%;
	background-color: var(--color-verde-footer);
}

.catalogueMain {
	margin-top: 3vh;
	min-height: 92vh;
}
.dropContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	z-index: 2 !important;
}
.containerCatalogue {
	background-color: #FFFFFF;
	display: flex;
	flex-wrap: wrap;
	/* margin-left: 20vw; */
	justify-content: space-around;
	padding-top: 5vh;
	padding-bottom: 5vh;
	width: 80vw;
}
.tituloCatalogue {
	font-family: Nexa;
	padding-top: 18vh;
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--text-color-claro);
}
.containerSearch {
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* padding-right: 3.5vw; */
	align-items: flex-end;
	margin-left: 20vw;
	width: 80vw;
}
.buttonAtras {
	height: 40px;
	left: 0;
	border: none;
	margin-left: 5vw;
	padding: 5px 15px;
	background-color: var(--color-verde);
	border-radius: 10px;
}
.atrasTitle {
	display: flex;
	align-items: flex-end;
}
.buttonAtras:hover {
	cursor: pointer;
	box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-webkit-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-moz-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
}
.buttonAtras:active {
	animation: myAnim 1s ease 0s 1 normal forwards;
}
@keyframes myAnim {
	0% {
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		transform: translateX(-50px);
	}
}
.searchCatalogue {
	display: flex;
	justify-content: flex-start;
	
}
.inputSearchCatalogue {
	width: 60vw!important;
	height: 2.8rem!important;
	padding-left: 30px !important;
}

.inputSearchCatalogue:focus {
	outline: none !important;
	box-shadow: none !important;
	border-color: var(--color-verde) !important;
}
.form-control-feedback {
	position: absolute;
	padding: 10px;
	pointer-events: none;
}

.divCatalogue {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	z-index: 1;
	/* width: 100%;
	height: 100%; */
}

/* .divCatalogue .linkDetail img {
	width: 100%;
	height: 100%;

	object-fit: cover;
} */

.ord > button {
	background-color: var(--color-verde) !important;
	border: none !important;
	box-shadow: none !important;
	height: 2.5rem;
	/* font-size: 10px !important; */
	font-family: Montserrat;
	display: flex;
	align-items: center;
	transition: transform 0.2s ease;
}

.dropCatalogue > button {
	background-color: var(--color-verde) !important;
	border: none !important;
	box-shadow: none !important;
	height: 2.5rem;
	display: flex;
	align-items: center;
	transition: transform 0.2s ease;
}
.dropCatalogue .dropdown-toggle {
	color: #000 !important;
}

.optionContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-height: 150px !important; /* Establece la altura máxima que desees */
	overflow-y: auto !important; /* Habilita el desplazamiento vertical si es necesario */
	text-align: start;
}

.filterOptionContainer{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 150px !important;
	max-height: 150px !important; /* Establece la altura máxima que desees */
	overflow-y: auto !important; /* Habilita el desplazamiento vertical si es necesario */
	text-align: start;
	border-bottom: 0.5px solid #d9d9d9 !important
}
.dropCatalogue > button:hover {
	background-color: var(--color-rosa-claro);
	border: none !important;
	/* box-shadow: none !important; */
	/* box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-webkit-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-moz-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important; */
}

.ord {
	height: 2.5rem;
	background-color: var(--color-rosa-claro);
	border: none !important;
	border-radius: 10px;
}
.ord > .dropdown-menu.show {
	padding-left: 25px;
	inset: 0px 0px auto auto !important;

	text-align: start !important;
	align-items: flex-start !important;
	justify-content: flex-end !important;
}
.selectedFilters{
	width: 15vw;
	margin-left: 6vw;
	padding-left: 1vw;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: left;
	text-align: start;
	/* background-color: var(--color-verde-footer) */
	background-color: #20563f;
}

.dropCatalogue > button:active {
	background-color: var(--color-rosa-claro);
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	transform: scale(0.9);
}
.ord > button:active {
	background-color: var(--color-rosa-claro);
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	transform: scale(0.9);
}
.dropCatalogue .dropdown-item:active {
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
}
.ord .dropdown-item:active {
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
}
.dropCatalogue .dropdown-item:hover {
	width: 100%;
	/* background-color:red!important; */
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.ord .dropdown-item:hover {
	width: 100%;
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.dropTituleCatalogue {
	padding-top: 5px;
	z-index: 2;
	color: var(--text-color) !important;
	padding-right: 10px;
}
.dropTituleCatalogue > h3 {
	font-size: 1.2rem;
}

.containerCatalogueAndFilter {
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start; /* Alineación vertical al inicio */
	gap: 20px; /* Espaciado entre las tarjetas y el filtrado */
	margin-bottom: 35px;
}

.filterSection {
	text-align: start;
	width: 15vw;
	margin-left: 6vw;
	padding-left: 1vw;
	background-color:	#FFFFFF
	
}

.filterOptions {
	display: flex;
	flex-direction: column;
	text-align: left;
	max-height: 70vh;
	/* overflow: scroll; */
	overflow-x: hidden;
}
.buttonCata{
	/* background-color:red !important; */
	justify-content: center!important;
	padding-right: 15px!important;
	background-color: var(--color-verde)!important;
	width: 70px!important;
	border:none !important;	
	margin-left: 25vh!important;
	}
	

.filterOption {
	cursor: pointer;

}

.filterOption:hover {
	cursor: pointer;
	text-decoration: underline;
	/* text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); */
}

.filterOptionX {
	margin-left: 7px;
	width: 30px !important;
	height: 30px !important;
	color: #fff;
	cursor: pointer;
	border-radius: 50%;
}
.dropCatalogue > .dropdown-menu.show {
	padding-left: 15px;

}
.filterOptionX:hover {
	cursor: pointer;
	text-decoration: underline;
	color: rgb(252, 0, 0) !important;
	/* text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); */
}

@media (max-width: 600px) {
	.textDropdownItemCataloque:hover {
    box-shadow: none !important;
}

	.filterOptionX {
		margin-left: 7px;
		width: 30px !important;
		height: 30px !important;
		color: rgb(158, 7, 7) !important;
		cursor: pointer;
		border-radius: 50%;
	}

	.buttonCata{
margin-top: 5vh;
		margin-left: 5vw!important;
		}

		.filterOp{
			/* color:red !important; */
			filter: none !important;
		}
		
	.headerCatalogue {
		position: fixed !important;
		z-index: 14 !important;
	}
	.fondoCatalogue {
		padding-top: 5vh !important;
	}
	
	.selectedFilters {
		padding-top: 2vh;
	}
	.containerSearch {
		padding-top: 3vh;
		flex-direction: column;
		gap:15px;
		justify-content: flex-start;
		align-items: center;
		padding-right: 0px!important;
		margin-bottom: 20px;
		width: 100vw;
		margin-left: 0px;
	}
	.searchCatalogue {
	
	}
	.optionContainer {
		width: 100%;
		border-bottom: 0.5px solid #d9d9d9 !important;
		margin-bottom: 5px;
	}

	.inputSearchCatalogue {
		width: 95vw !important;
		margin-right: 0;
	}
	.dropCatalogue > .dropdown-menu {
		transform: translate3d(0, 42px, 0) !important;
		background-color: var(--color-rosa-claro) !important;
		
	}
	.activeFilter {
		font-weight: 1000;
	}
	.ord > .dropdown-menu.show {
		position: absolute !important;
		inset: 0px 0px auto auto !important;
		transform: translate3d(30vw, 42px, 0) !important;
		/* background-color: var(--color-rosa-claro); */
		background-color: #e9e9e9 !important;
		text-align: start !important;
		align-items: flex-start !important;
		justify-content: flex-end !important;
		z-index: 5 !important;
	}
	.dropCatalogue > .dropdown-menu.show {
		position: absolute !important;
		inset: 0px 0px auto auto !important;
		transform: translate3d(0, 42px, 0) !important;
		/* background-color: var(--color-rosa-claro); */
		background-color: #e9e9e9 !important;
		text-align: start !important;
		align-items: flex-start !important;
		justify-content: flex-end !important;

	}
	.textDropdownItemCataloque.dropdown-item {
		color: #5C5C5C !important;
		font-family: Montserrat;
font-size: 14px;
		
	}
	.containerCatalogueAndFilter {
		display: flex;
		flex-direction: row;
		align-items: flex-start; /* Alineación vertical al inicio */
	}
	.containerCatalogue {
		display: flex;
		flex-wrap: wrap;
		/* margin-left: 20vw; */
		justify-content: center;
		padding-top: 5vh;
		padding-bottom: 5vh;
		width: 100vw;
	}
	.dropContainer{
		gap:10px
	}
}
