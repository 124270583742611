.newClientContainer {
	width: 40vw;
	border-radius: 0 0 10px 10px;
}
.headerNewClient {
	width: 40vw;
	background-color: var(--color-rosa-claro);
}

@media (max-width: 550px) {
	.newClientContainer {
		width: 95vw !important;
		left: 0;
	}
	.headerNewClient {
		width: 95vw !important;
	}
	p {
		font-size: 0.8rem;
	}
}

@media (max-width: 1300px) {
	.newClientContainer {
		width: 60vw;
		left: 0;
	}
	.headerNewClient {
		width: 60vw;
	}
	p {
		font-size: 0.8rem;
	}
}

@media (max-width: 900px) {
	.newClientContainer {
		width: 75vw;
		left: 0;
	}
	.headerNewClient {
		width: 75vw;
	}
	p {
		font-size: 0.8rem;
	}
}
