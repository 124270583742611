.carousel-containerInfo {
	position: relative;
	width: 100vw;
	height: 60vh; /* Ajusta la altura deseada para el carrusel */
	z-index: 1;
	margin-top: 2vh;
}

.carousel-item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100%;
}
.scroll-indicator-carousel {
width: 50px;
border-radius: 50%;
background-color: rgba(240, 248, 255, 0.37);
  /* align-items: center; */
  height: 50px; /* Ajusta la altura según sea necesario */
  animation: bounceAnimation 1s infinite alternate;
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
.carousel-item > img {
	max-width: 100vw;
	max-height: 82vh;

	object-fit: cover; /* Ajusta el tamaño de la imagen mientras mantiene su proporción */
}

@media (max-width: 550px) {
	.carousel-container {
		width: 100%;
		height: 50vh; /* Ajusta la altura deseada para el carrusel */
		z-index: 1;
	}
}
