.edit-icon-aliance {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	position: absolute;
	top: 5%;
	right: 5%;
	background-color: var(--color-verde);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-aliance:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.danger-edit {
	margin-top: 15px;
}
