.divFooter {
	height: 8vh;
	min-height: 8vh;
	padding: 1vh 3vh;
	background-color: var(--color-verde);
}

.divFooter > h6 {
	margin-left: 8px;
	font-family: Montserrat;
}

.terminos {
	width: 100%;
	color: var(--text-color-navbar);
	text-decoration: none;
	transition: 0.3s;
}
.terminos:hover {
	color: var(--text-color-navbar);
	font-weight: bold;
}
@media (max-width: 550px) {
	.divFooter {
		height: 8vh;
		min-height: 8vh;
		padding: 1vh 1vh;
		background-color: var(--color-verde);
	}
	.divFooter > h6 {
		/* margin-left: 8px; */
		font-size: 12px;
		font-family: Montserrat;
	}
}