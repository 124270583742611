.carouselOfertasCart-container {
	width: 100%;
	/* z-index: 1; */

	border-bottom: 0.2px solid #ddd9d9;

	scrollbar-width: none;
	-ms-overflow-style: none;

}
.carouselOfertasCart-container::-webkit-scrollbar {
	display: none;
}
.tituloCarouselOfertasCart {
	font-size: 1.2rem;
}

.rightArrow {
	background-color: #5353535b;
	border: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	position: absolute;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text-color);
}

.leftArrow {
	background-color: #5353535b;
	border: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	position: absolute;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text-color);
}
.rightArrow:hover,
.leftArrow:hover {
	background-color: rgba(0, 0, 0, 0.453);
}
@media (max-width: 550px) {
	.containerOfertasCarouselCart {
		width: 80vw;
	}
}
