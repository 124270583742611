.containSocial {
	width: 100%;
	display: flex;
	justify-content: center !important;
}
.tituloSocial {
	font-family: Montserrat;
	font-weight: bold;
}
.containSocial__img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	background-color: var(--color-verde);
	transition: transform 0.2s ease;
}

.containSocial__img:hover {
	transform: scale(0.9);
}

.social__img {
	width: 100%;
	height: 100%;
	padding: 10px;
	object-fit: contain;
}
