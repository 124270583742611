.card {
	align-items: center;
	background-color: transparent;
	border: none;
}
.cardcitita {
	width: 100%;
}

.new-sale {
	margin-left: 2px;
	margin-bottom: 2px;
	/* max-width: 50px; */
	background-color: #ff61f4;
	color: #c1fe72;

}
.vegan {
	margin-left: 2px;
	cursor: pointer;
	width: 35px;
	border-radius: 50%;
}
.christmas {
	margin-left: 2px;
	cursor: pointer;
	width: 50%;
	border-radius: 10px;
}

.containerImage {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	object-fit: cover;
	width: 250px;
	border: none;
	padding: 0;
	background-color: transparent;
}
.containerImage:hover {
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
.linkDetail {
	padding: 0 !important;
	overflow: hidden;
}
.linkDetail > img {
	width: 100%;
	height: 250px;
	object-fit: cover;
}
.ofertas {

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	position: absolute;
	z-index: 1;
}
.tituloCard {
	/* position: absolute; */
	width: 100%;

	background-color: rgba(0, 128, 0, 0) !important;
	overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Cambia el número según tus necesidades */
  -webkit-box-orient: vertical;
	/* bottom: 51%;
	margin-bottom: 0; */
	font-family: Montserrat;
	margin-bottom: 0;
	padding: 0 10px;
	text-align: center;
	font-weight: bold;
	color: var(--text-color-claro);
	padding-bottom: 0px;
}
.cardCarousel {
	width: 180px;
	height: 150px;
	object-fit: cover;
	z-index: 0;
}
.cardCarouselImg {
	width: 100%;
}
.cardcita {
	z-index: 0;
	width: 100%;
	/* height: 100%; */
	height: 150px;
	object-fit: cover;
	/* margin-top: 16px; */
}
.card.hovered img {
	transition: transform 1s ease;

	-webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0.9;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0.9;
	}
	100% {
		opacity: 1;
	}
}

.btn-outline-secondary {
	height: 40px;
	width: 100px;
	border: 1px solid #5c5c5c
}

.btn-outline-secondary:hover {
	border-color: var(--color-naranja-fuerte);
	background-color: var(--color-rosa-claro);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
.card-body {
	height: 150px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background-color: white;
}
.card-body > p {
	overflow: hidden;
}
.card-text {
	font-family: Nexa;
	margin-bottom: 0;
}
.carrito {
	font-weight: 600;
}

.edit-icon-card {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	position: absolute;
	top: 5%;
	right: 5%;
	background-color: var(--color-verde);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-card:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.--nodisponible {
	backdrop-filter: grayscale("80%") blur("2px");
	color: lightgray;
	user-select: none;
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}

.abbrNoLink {
	text-decoration: none !important;
	font-variant: none;
}

#containerCards {
	margin-bottom: 5vh;
	background: whitesmoke;
}
.album {
	background: whitesmoke !important;
}
.contenedorPreciosBotones {
	width: 100% !important;
}

.oferta {
	color: red !important;
	font-weight: 600;
}
.mobile-card {
	object-fit: contain;
	width: 45vw;

	height: auto; /* Altura automática en vista móvil */
	max-height: 300px; /* Altura máxima en vista móvil (ajusta según tus necesidades) */
	margin-bottom: 10px;
}


@media (max-width: 550px) {
	.btn-outline-secondary {
		padding:0px;

		height: 30px;
		width: 60px;
		border: 1px solid #5c5c5c;
		font-size: 10px;
	}
	.ofertas {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		position: absolute;
		z-index: 2;
	}

.new-sale {
	margin-left: 2px;
	margin-bottom: 2px;
	z-index: 2;
}
.vegan {
	margin-left: 2px;
	cursor: pointer;
	width: 30px;
	border-radius: 50%;
	z-index: 2;
}
.edit-icon-card {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	position: absolute;
	top: 5%;
	right: 5%;
	background-color: var(--color-verde);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 2;
}
.carrito{
	z-index: 2;
}
}
