.container-detalle-producto {
	border-top: solid 1px rgba(0, 0, 0, 0.275);
	border-bottom: solid 1px rgba(0, 0, 0, 0.275);
	margin-bottom: 10px;
	padding: 10px;
}

.botonModalConfirmacion:disabled {
	background-color: dimgrey; /* Color de fondo cuando está deshabilitado */
	border-color: dimgrey; /* Color del borde cuando está deshabilitado */
	cursor: not-allowed !important; /* Cambia el cursor a "no permitido" */
}

.revisa {
	text-align: center;
	font-size: 1rem;
	color: red;
}
.itemEditPurchase:hover {
	background-color: var(--color-verde-footer);
}
.mediosPagoDrop {
	margin-left: 22%;
}
.mediosPagoDrop > button {
	color: var(--color-texto-claro);
	border: none;

	background-color: var(--color-rosa-claro);
}
.mediosPagoDrop > button:hover {
	color: var(--color-texto-claro);
	border: none;

	background-color: var(--color-verde-footer);
}

.mediosPagoDrop > button:active {
	color: var(--color-texto-claro);
	border: none;

	background-color: var(--color-rosa-claro);
}

.mediosPagoDrop > button:before {
	color: var(--color-texto-claro);
	border: none;

	background-color: var(--color-rosa-claro);
}
.mediosPagoDrop > button:after {
	color: var(--color-texto-claro);
	border: none;

	background-color: var(--color-rosa-claro);
}
.mediosPagoDrop > button:focus {
	color: var(--color-texto-claro);
	border: none;

	background-color: var(--color-rosa-claro);
}

/* Centra el texto de los elementos descendientes del DropdownButton */
.mediosPagoDrop .dropdown-item {
	text-align: center;
	color: var(--color-texto-claro) !important;
}

/* Ajusta el posicionamiento del Dropdown hacia la derecha */
.mediosPagoDrop .dropdown-menu {
	left: auto;
	right: 0;
}

.mediosPagoDrop .dropdown-menu.show {
	padding-left: 5%;
}
