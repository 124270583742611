.pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.botonPaginado {
	color: black !important;
}

.page-link.botonPaginado.active {
	background-color: var(--color-verde-footer);
	border: 0.5px solid gray;
	text-decoration: underline;
	z-index: 1;
}

.page-item {
	font-family: Nexa;
}
