.divNavBarRosa {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center !important;
	width: 100%;
	min-height: 17vh;
	/* min-height: 17vh; */
	padding: 0.2vh 0px 0.2vh 7vw !important;
	background-color: var(--color-rosa-super-clarito)!important;
	/* overflow: hidden; */
	z-index: 2000000;
}
.navbaaar {
	align-items: flex-end !important;
}

.logoRosa > img {
	height: 100%;
	background-color: var(--color-rosa-super-clarito);
}

.logoRosa {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 17vh;
	height: 17vh;
	width: 35%;
	/* width: 15vw; */
	/* max-width: 100vw; */
	text-decoration: none !important;
}
.linkNavBar {
	color: var(--text-color-navbar) !important;
	/* color:#5c5c5c!important; */
  font-size: 18px;
	font-weight: 600;
	font-family: Montserrat;
}

.linkNavBar:hover {
	text-decoration: none !important;
	cursor: pointer;
	color: var(--text-color);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
	
}
.linkNavBar:focus {
	text-decoration: none !important;
	color: var(--text-color);
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.link-drop {
	width: 100%;

	text-decoration: none;
	color: var(--text-color-claro);
	text-align: center;
}
.link-drop:active {
	background-color: var(--color-rosa-claro);
	color: var(--text-color) !important;
}
.link-drop:hover {
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

	cursor: pointer;
	color: var(--text-color);
	font-weight: bold;
}

.link-drop:focus {
	text-decoration: underline;
	color: var(--text-color);
	font-weight: bold;
}

.bolsita {
	position: relative;
	padding-bottom: 0;
	padding-top: 0;
}
.enCarrito {
	display: flex;
	font-size: 12px;
	background-color: var(--text-color-navbar);
	color: var(--text-color-claro);
	align-items: center;
	justify-content: center;
	position: absolute;
	object-fit: contain;
	height: 15px;
	width: 15px;
	top: 40px;
	left: 10px;
	border-radius: 50%;
}
.dropdowncito.button.dropdown-menu{
	margin-left: -40px !important;
}
.dropdown-menu.show {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column !important;
}
.container-items {
	display: flex;
	justify-content: center !important;
	align-items: center !important;

	flex-direction: column;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: transparent;
	text-decoration: underline;
	color: var(--text-color);
	font-weight: bold;
}
.derecha {
	display: flex;
	/* justify-content: flex-end !important; */
	margin-right: 0 !important;
	width: 100% !important;
	justify-content: space-between !important;
	align-items: flex-end;
}
.derecha > section {
	display: flex;
	justify-content: flex-end;
	margin:none!important
}
.navbar-collapse {
	margin: 0;
	width: 65%;
}

.navbar-collapse nav {
	padding: 0 1vw;
	text-align: left;
	width: 100%;
}
.navbar-collapse nav .linkNavBar {
	padding-left: 2vw;
}
.dropdowncito > button {
	background-color: var(--color-rosa-claro) !important;
	border: none !important;
	box-shadow: none !important;

	display: flex;
	align-items: center;
	transition: transform 0.2s ease;
}
.dropdowncito .dropdown-toggle {
	color: var(--text-color-claro) !important;
}

.dropdowncito > button:hover {
	background-color: var(--color-rosa-claro);
	border: none !important;
	/* box-shadow: none !important; */
	box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-webkit-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-moz-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
}
.dropdowncito > button:active {
	background-color: var(--color-rosa-claro);
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	transform: scale(0.9);
}
.dropdowncito .dropdown-item:active {
	background-color: var(--color-rosa-claro);
	color: var(--text-color) !important;
}
.dropdowncito .dropdown-item:hover {
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.title {
	display: flex;
	align-items: center;
	text-align: center;
}
.titleDrop {
	font-size: 1rem;
	color: var(--text-color);

	margin-bottom: 0;
}
.iniciarSesion {
	display: flex;
	align-items: center;
}
.iniciarSesionLink {
	text-decoration: none;
}
.iniciarSesionLink > button {
	background-color: var(--color-rosa-claro);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	border: none;
	display: flex;
	align-items: center;
	color: var(--text-color);
	font-weight: 600;
	padding: 6px 12px;
	border-radius: 10px;
	font-size: 1.2rem;
}
.iniciarSesionLink > button:hover {
	background-color: #e9bcbf;
}

.show > .btn-primary.dropdown-toggle {
	background-color: var(--color-rosa-claro);
	border: none;
}
.dropdowncito2 {
	z-index: 3;
	color: var(--text-color) !important;
	padding-right: 10px;
}

.navbar-toggler {
	background-color: var(--color-rosa-claro);
	color: var(--text-color);
	outline: none;
	border: none;
}
.navbar-toggler:active {
	outline: none;
	border: none;
}
.navbar-toggler:focus {
	outline: none;
}
.bi {
	color: var(--text-color);
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.635));
}

.bi-person {
	font-size: 1.4rem;
	padding-right: 10px;
}
.bi-gem {
	padding-right: 5px;
}
.dropNav .dropdown-menu.show {
	text-align:left !important;
margin-left: -5vw !important;
}
.custom-toggle-image {
	background: url('../../assets/images/CORAZON-BLANCO.png') no-repeat center center;
	background-size: contain;
	/* border: none; */
	
	background-color: none;

	width: 60px;
	height: 60px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform 0.2s ease;
}
.custom-toggle-image .navbar-toggler-icon {
	display: none;
}

.custom-toggle-image:active {
	transform: scale(0.9);
}
.custom-toggle-image:focus {
	outline: none;
	border: none;
	box-shadow: none;
}

@media (max-width: 550px) {
	.divNavBar {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: flex-end !important;
	}
	.navbaaar {
		align-items: flex-end !important;
	}
	.logo {
		min-height: 100%;
		height: 100%;
		width: 65vw;
		/* height: auto; */
		/* width: 15vw; */
		/* max-width: 100vw; */
		text-decoration: none;
	}
	.logo > img {
		width: 100%;
		height: 100%;
	}
	.derecha {
		align-items: flex-start !important;
		width: 100%;
	}
	.derecha > section {
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		width: 100%;
	}
	.iniciarSesion {
		width: 100%;
		margin-top: 8px;
	}
	.iniciarSesionLink {
		width: 100%;
	}
	.iniciarSesionLink > button {
		width: 100%;
		font-size: 1.2rem;
		justify-content: center;
	}
	.dropdowncito {
		width: 100%;
	}
	.dropdowncito > button {
		margin-top: 1vh;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.dropdown-menu {
		background-color: var(--color-rosa-claro);
		width: 250px;
		border: none;
	}
	a {
		color: var(--text-color-navbar) !important;

	}
	.dropdown-item {
		color: var(--text-color-navbar) !important;
	}
	.btn-primary {
		border: none;
		outline: none;
	}
	.btn-primary:focus {
		border: none;
		outline: none;
	}
	.btn-primary:hover {
		border: none;
		outline: none;
	}
	.btn-primary:active {
		border: none;
		outline: none;
	}
}