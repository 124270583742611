header {
	top: 0;
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
.fondoLanding{
  height: 83vh;
}

main {
	margin-top: 17vh;
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
	overflow-x: hidden;
}


.fade-in {
  animation: fadeInAnimation 2s ease-in forwards;
  opacity: 0;
}

.fade-in2 {
  animation: fadeInAnimation 4s ease-in forwards;
  opacity: 0;

}
.fade-in3 {
  animation: fadeInAnimation 5s ease-in forwards;
  opacity: 0;

}


@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.background-image {
background-color: #ffffff;
  position: relative;
}

.reveal-image-container {
  position: relative;
  width: 100%;
  height: 400px; /* Altura fija para revelar la imagen */
  overflow: hidden;
  margin-top: 17vh; /* Ajusta según el tamaño de tu header */
}

.reveal-image-content {
  position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  width: 100%;
  height: 100%;
}

.reveal-content {
  height: 100%;
  
}

.fixed-background-image {
 
  position: fixed;
z-index: -1;
  top: 0;
  left: 0;
  width: 100%;

  object-fit: cover; /* Ajusta según sea necesario */
  /* opacity: 0.5; Ajusta la opacidad según sea necesario */
}



.scroll-indicator {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 50px; /* Ajusta la altura según sea necesario */
  animation: bounceAnimation 1s infinite alternate;
}

.scroll-indicator2 {
	margin-top: 5vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 50px; /* Ajusta la altura según sea necesario */
  animation: bounceAnimation 1s infinite alternate;
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

.sectionCarousel {
	width: 100vw;
	height: 75vh;
}
.sectionStamp {
	margin-top: 5vh;
	background-color: var(--color-rosa-claro-trans);
}

.sectionAbout {
	background-color: var(--color-rosa-claro-trans);
	--mask: radial-gradient(78.1px at 50% calc(100% - 110px), #000 99%, #0000 101%) calc(50% - 100px)
			0/200px 100%,
		radial-gradient(78.1px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50% calc(100% - 50px) /
			200px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}

.fondohome {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;
	bottom: 0;
	left: 5%;
	background-color: var(--color-verde);
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}

.fondohome:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

 

@media (max-width: 550px) {
	main {
		margin-top: 10vh;
	}
	.sectionCarousel {
		top: -10vh;
		width: 100%;
		height: 55vh;
	}
	.fondohome {
		top: 20vh;
	}
}