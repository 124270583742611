.loaderContainer {
	position: fixed;
	z-index: 99999999;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.648);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow-y: hidden;
	margin: 0;
	bottom: 0;
	top: 0;
}
