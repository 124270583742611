header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
main {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
}

.containerCategory2 {
	margin-top: 5vh;
}
/* .sectionCategory {
	display: flex;
	align-items: center !important;
	justify-content: space-between;
} */
.tituloCategory {
	font-size: 2rem !important;
	font-weight: 600;
	font-family: Montserrat;

	padding-top: 17vh !important;
	font-size: 1.5rem;
	color: var(--text-color-claro);
}
.fondoPrevButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 35px;
	position: absolute;
	top: 20vh;
	font-size: 12px;
	left: 5%;
	background-color: var(--color-verde-footer);
	color: #ffffff;
	font-weight: 600;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}

.fondoPrevButton:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

@media (max-width: 990px) {
	.sectionCategory {
		align-items: center;
		flex-direction: column;
	}
	.containerCategory {
		width: 65vw;
	}
}

@media (max-width: 550px) {
	.fondoPrevButton {
		top: 20vh;
	}

	.tituloCategory {
		margin-top: 5vh;
	}

	.containerCategory2 {
		margin-top: 0;
	}

	.sectionCategory {
		align-items: center;
		flex-direction: column;
	}
}
