.containerCategory {
	position: relative;
	width: 17em;
	height: 17em;
	margin-top: 5vh;
	border-radius: 10px;
	border: 2px solid #53535349;
	display: inline-block;
}

.titulito {
	background-color: var(--color-rosa-trans) !important;
	color: #ffffff !important;
	font-family: Montserrat !important;
	text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6) !important;
}

.containerCategory::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(217, 217, 217, 0.179);
	/* background-color: rgba(0, 0, 0, 0.5); Color con transparencia */
}
.containerCategory:hover {
	cursor: pointer;
}
.containerCategory:hover img {
	filter: blur(1.5px);
}
.containerCategory > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	/* filter: blur(1.5px); */
}

.containerCategory > h3 {
	position: absolute;
	top: 45%;
	color: rgba(0, 0, 0, 0.719);
}
.edit-icon-prevCatalogue {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	position: absolute;
	bottom: 5%;
	right: 5%;
	background-color: var(--color-verde-claro);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-prevCatalogue:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
