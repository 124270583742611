.modalEditContainer {
	position: fixed !important;
	z-index: 50 !important;
}

.imgProductContainer {
	padding-top: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.tituloRemoveVariant {
	display: flex;
}
.removeTags {
	display: flex;
	align-items: center;
}
.imgEdit {
	display: flex;
	flex-direction: column;

	align-items: center;
}

.imgEditTag {
	object-fit: cover;
}
