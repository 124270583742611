.dateSelector {
	margin-bottom: 5vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.mainCajaDiaria{
margin-top:7vh;
	margin-bottom: 2vh;
	min-height: 95vh;
}
.cajaButtons {
	display: flex;
	flex-direction: column;
}
.picker {
	margin-left: 5px;
	margin-right: 7vw;
}
.abierta {
	background-color: rgba(0, 128, 0, 0.459) !important;
	color: white !important;
}
.cerrada {
	background-color: rgba(226, 5, 5, 0.459) !important;
	color: white !important;
}
/* Estilo para el fondo verde cuando el checkbox está marcado */
.checked {
	background-color: rgba(25, 102, 25, 0.678) !important;
	color: white; /* Cambia el color del texto para que sea legible en el fondo verde */
}
.buttonsContainerCaja {
	align-items: center;
	width: 100vw;
	height: auto;
	padding-bottom: 5vh;
	display: flex;
	flex-direction: row;

	gap:"10px"!important
}
.saldoFinal {
	background-color: rgba(25, 102, 25, 0.678) !important;
	color: white !important;
}
.montoSaldo {
	color: white !important;
	font-weight: 500 !important;
}
@media (max-width: 700px) {
	.buttonsContainer {
		padding: 15px !important;
	}
	.cajaButtons {
		align-items: center;
	}
	.picker {
		margin-right: 25vw;
	}
	
}
