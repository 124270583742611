.containerPromotion2 {
	/* height: 50vh; */
	width: 100%;
	background-repeat: no-repeat;
	object-fit: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.containerPromotion2 > img {
	/* --mask: radial-gradient(78.1px at 50% 110px, #000 99%, #0000 101%) calc(50% - 100px) 0/200px 100%,
		radial-gradient(78.1px at 50% -60px, #0000 99%, #000 101%) 50% 50px/200px 100% repeat-x;
	-webkit-mask: var(--mask); */
	/* mask: var(--mask); */
	height: 50vh;
	width: 100%;
	background-repeat: no-repeat;
	object-fit: cover;
}

.edit-icon-promotion2 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	position: absolute;
	bottom: 5%;
	right: 5%;
	background-color: var(--color-verde-claro);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.buttoncito{

	background-color: var(--color-verde);
transform: scale(1.1);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);

}

.edit-icon-promotion2:hover {
	background-color: var(--color-verde);
	transform: scale(1.1);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
