.containAbout2 {
	display: flex;
	justify-content: center;

		position: relative;
		/* background-color: #ffffff00; */
		overflow: hidden; /* Asegura que los pseudo-elementos no se desborden */
	

}


.img2 > img {
	width: 100%;
	object-fit: contain;
	mask-repeat: no-repeat;
  border-radius: 5px;
	mask-repeat: no-repeat;
}


.edit-icon-about {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 15%;
	right: 15%;
	background-color: var(--color-verde-claro);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-about:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.edit-icon-aboutText {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin: 2px 2px;
	background-color: var(--color-verde);
	color: var(--text-color);
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-aboutText:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.guardarDesc {
	margin: 5px 0 5px 0;
	background-color: var(--color-verde);
	color: var(--text-color);
}
.guardarDesc:hover {
	color: var(--text-color);
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.img2 {
	width: 50%;
	position: relative;
	transform: translateY(-1250%);
	transition: transform 1s ease;
}

.text2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5vw;
	justify-content: space-around;
	width: 50%;
	/* transform: translateX(-250%); */
	transition: transform 1s ease;
}
.text2 > h5 {
	color: var(--color-oscuro);
	max-width: 75ch;
	text-align: center;
}
.text2 > p {
	color: var(--color-oscuro);
	max-width: 75ch;
	text-align: center;
}
.aboutLik {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}
.aboutLik > button {
	display: flex;

	align-items: center;
	padding: 8px 12px;
	border: none;
	border-radius: 10px;
	font-size: 1rem;

	background-color: var(--color-verde);
	color: var(--text-color);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.aboutLik > button:hover {
	color: var(--text-color);
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.animate-image2 {
	transform: translateX(0);
}
.animate-text2 {
	transform: translateX(0);
}

@media (max-width: 550px) {
	.containAbout2 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* max-height: 50vh; */
	}
	.img2 {
		width: 100%;
	}

	.text2 {
		width: 100%;
		padding-bottom: 80px;
	}
}