.alianzaContainer {
	width: 45vw; /* Ajusta el ancho deseado */
	height: 35vh; /* Ajusta la altura deseada */
	margin-bottom: 4vh;
	/* Otros estilos que desees para cada alianza */
	background-color:#d9d9d98e;
	padding-bottom: 10%;
	--mask: radial-gradient(78.1px at 50% calc(100% - 110px), #000 99%, #0000 101%) calc(50% - 100px)
			0/200px 100%,
		radial-gradient(78.1px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50% calc(100% - 50px) /
			200px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
	position: relative;
}

.imgAlianza {
	display: flex;
	align-items: center;
	width: 50%;
	padding-left: 0;
}

.botonesAlianza {
	display: flex;
	gap:"10px"

	/* flex-direction: column; */
}

.alianceButton{
	border-radius: 5px;
	border: none!important;
background-color: #20563f;
color: #fff;
	height: 42px;
	align-items: center;
	padding-left: 5px;
	padding-right: 5px;
}
.alianceButton:hover {
  background-color: #287a55;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.alianceButtonBene{
	border-radius: 5px;
	border: none!important;
	color: #fff;
background-color:#e5c4c9; 
text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
align-items: center;
padding-left: 5px;
padding-right: 5px;
}

.alianceButtonBene:hover {
  background-color: #cf98a0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.aboutLik > button {
	margin: 5px;
}

.imgAlianza > img {
	width: 80%;
	height: 80%;
	overflow: hidden;
	object-fit: cover;
	border-radius: 10px 10px;
}

.alianzaContainer .text {
	padding: 10px; /* Agrega espacio para el texto */

	height: 80%;
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center !important;
}

.alianzaContainer p {
	/* flex-grow: 1;  */
	font-family: Montserrat;
	height: 100%;
	font-size: 1rem;
	overflow: hidden; /* Oculta el texto que desborda del párrafo */
}

@media (max-width: 550px) {
	.alianzaContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70vh;
		width: 90vw;
		padding-bottom: 20%;
	}
	.alianzaContainer .text {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.imgAlianza {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}
@media (max-width: 1080px) {
	.alianzaContainer {
		width: 100%;
	}
}
