/* .modal-dialog,
.modal-lg,
.modal-content {
	height: 100vh;
} */
.containerModalCart {
	position: absolute;
	z-index: 11 !important;

	overflow: scroll !important;
}
.medioPago {
	display: flex;
	flex-direction: column;
	text-shadow:none !important
}
.modal-right {
	margin-top: 1vh !important;
	left: 25vw !important;
	margin-left: auto !important;
	margin-right: 0 !important;
}
.modal-right .modal-backdrop {
	margin-left: 0 !important;
}
select {
	text-align: center;
	background-color: var(--color-verde-footer);
}
.headerCart {
	display: flex;
	background-color: var(--color-verde);
	width: 100%;
}
.divTitulo {
	display: flex;
	align-items: flex-end;
	color: var(--text-color);
	font-family: Montserrat;
}
.headerCart > button {
	margin-left: 5px !important;
}

.containerBodyModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.sectionItemCart {
	width: 100%;
	max-height: 30vh;
	overflow-y: scroll;
	padding-bottom: 25px;
}
.sectionItemCartCerrado {
	width: 100%;
	max-height: 55vh;
	overflow-y: scroll;
}
.vaciarCarrito {
	width: 100%;
	color: var(--color-verde);
	font-family: Montserrat;
	/* text-align: center; */
}
.vaciarCarrito:hover {
	font-weight: bold;
	cursor: pointer;
}
.sectionItemCartSale {
	position: relative;
	width: 100%;
	max-height: 30vh;
	border-top: 0.2px solid #ddd9d9;
}
.buttonCerrarCartSale {
	position: absolute;
	top: 5px;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
}
.buttonCerrarCartSale:hover {
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.buttonAbrirCartSale {
	width: 100%;
	color: var(--color-verde);
}
.buttonAbrirCartSale:hover {
	font-weight: bold;
	cursor: pointer;
}
.textoCart {
	border-top: 1px solid #d9d9d9;
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	color: var(--text-color);
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	font-family: Montserrat;

	background-image: url("../../assets/fondos/FONDO1.png");
	background-size: 1000px;
	/* opacity: 0.5; */
}

.h4Modal {
	color: #ddd9d9;
}
.divTransparencia {
	width: 100%;
	background-color: white;
}
.divTransparencia > h2 {
	text-shadow: none!important;
	font-size: 1.2rem;
	font-family: Montserrat;
}
.divTransparencia > h3 {
	text-shadow: none!important;
	font-size: 1.2rem;
}
.divTransparencia > h6 {
	text-shadow: none!important;
	font-family: Montserrat;
	font-size: 0.8rem;
}
.containerModal > .modal-dialog {
	margin: 0;
	position: absolute;
	top: 10px;
	right: 10px;
}
.btn-close {
	margin-left: 0;
}
.divBolsaVacia {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.divBolsaVacia > img {
	width: 5vw;
	margin-bottom: 10px;
}
.footerCart {
	display: flex;
	justify-content: center;
}

.botonFinalizarCompra {
	background-color: var(--color-rosa-claro);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	border: none;
	display: flex;
	align-items: center;
	color: var(--text-color);
	font-weight: 600;
	padding: 6px 12px;
	border-radius: 10px;
	font-size: 1.2rem;
}
.botonFinalizarCompra:hover {
	background-color: #e9bcbf;
}
.botonFinalizarCompra:disabled {
	background-color: var(--color-rosa-claro);
}

/* .sectionModalBorrar {
	margin-top: 20px;
}
.modaaaaal {
	margin-top: 50px;
} */
@media (max-width: 550px) {
	.modal-right {
		left: 0vw !important;
	}
	.modal-right .modal-backdrop {
		margin-left: 0 !important;
	}
	.containerModal {
		position: absolute;
		z-index: 11 !important;
	}
	.modal-dialog {
		position: initial;
	}
	.textoCart > h2 {
		font-size: 1.1rem;
	}
	.textoCart > h3 {
		font-size: 1.1rem;
	}
}
