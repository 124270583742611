.containerItemCartSale {
	width: 100%;
	max-height: 150px;
	display: flex;
	justify-content: space-between;

	/* border-right: 0.2px solid #ddd9d9; */
}
.imgCartSaleItem {
	width: 70px;
	height: 70px;
	object-fit: cover;
}
.containerDatosCartSale {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}
.tituloYPrecioSale {
	display: flex;
	justify-content: space-between;
	text-align: left;
}
.tituloYPrecioSale > h3 {
	font-size: 12px;
	margin-bottom: 0;
}
