.carouselOfertas-container {
	width: 100%;
	/* height: 77vh; Ajusta la altura deseada para el carrusel */
	z-index: 1;
}
.tituloOfertas {
	font-family: Poppins;
	font-weight: 400;
	/* font-weight: bold; */
	color: #157847;
}

.edit-icon-sales {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;

	top: 5%;
	right: 5%;
	background-color: var(--color-verde);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-sales:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}




@media (max-width: 550px) {
	.itemOfertaHome {
		display: flex;
		justify-content: center;
	}
	.tituloOfertas {
		font-size: 18px;
	}
}
