header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}

main {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
}
.containerAlianzas {
	margin-top: 17vh;
}
main > h2 {
	margin-top: 8vh;
	font-size: 2rem;
}
main > h4 {
	font-size: 1rem;
}

.containerAlianzas > h2 {
	padding-top: 17vh;
}
.containAbout {
	margin-top: 5vh;
	display: flex;
	justify-content: center;
	/* max-height: 50vh; */
}
.sectionAlianza {
	display: flex;
	/* margin-top: 17vh !important; */
	/* height: 50vh; */
	--mask: radial-gradient(78.1px at 50% calc(100% - 110px), #000 99%, #0000 101%) calc(50% - 100px)
			0/200px 100%,
		radial-gradient(78.1px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50% calc(100% - 50px) /
			200px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}
.rosadito {
	background-color: var(--color-rosa-claro-trans);
}
.sectionAlianzaSinMargin {
	display: flex;

	--mask: radial-gradient(78.1px at 50% calc(100% - 110px), #000 99%, #0000 101%) calc(50% - 100px)
			0/200px 100%,
		radial-gradient(78.1px at 50% calc(100% + 60px), #0000 99%, #000 101%) 50% calc(100% - 50px) /
			200px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}
.verdecito {
	background-color: var(--color-verde-footer);
}
.inv {
	display: flex;
	flex-direction: row-reverse;
}
.newMedida > img {
	width: 50%;
}
.aLink{
	color:"#5c5c5c !important"
}

.fondoAlianzasButton {
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;

	top: 20vh;
	left: 5%;
	background-color: #0f351865;
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}

.fondoAlianzasButton:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

@media (max-width: 550px) {
	.inv {
		display: flex;
		flex-direction: column;
	}
}
