.containerConfirmation {
	background-color: #53535346;
	z-index: 100000000000;
}
.modalConfirmationContainer {
	background-color: rgb(223, 223, 223);
	font-family: Montserrat;
	/* background-color: rgba(250, 13, 230, 0.082); */
}
.headerModalConfirmation {
	background-color: var(--color-verde);
	color: var(--text-color);
	font-family: Montserrat;
}
.modal-content {
	margin-right: 50px;
}
.modalConfirmationContainer {
	border-radius: 10px;
}

.divMagico {
	display: flex;
	justify-content: center;
	align-items: center;
}

.botonModalConfirmacion {
	background-color: var(--color-verde)!important;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	font-family: Montserrat;
	border: none;
	display: flex;
	align-items: center;
	color: var(--text-color);
	font-weight: 600;
	padding: 6px 12px;
	border-radius: 10px;
	font-size: 1.2rem;
	margin-left: 8px;
}
.botonModalConfirmacion:hover {
	background-color: #e9bcbf;
}
.buttonSecondary {
	font-weight: 600;
	font-size: 1.2rem;
	padding: 6px 12px;
	border-radius: 10px;
	border: none;
}

@media (max-width: 550px) {
	.modal-content {
		margin: 0;
		width: 100%;
	}
}
