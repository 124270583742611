/* .testimonios {
	height: 25vh;
}

.testimoniosCarousel {
	margin-top: 17vh;
	height: 77vh;
	z-index: 1;
}

.terminos {
	width: 100%;
	transition: 0.3s;
}

.carousel__img {
	width: 100%;

	object-fit: cover;
	height: 100%;
} */
.carousel-container-market {
	width: 100vw;
	height: 100vh; /* Ajusta la altura deseada para el carrusel */
	z-index: 1;
	margin-top: 2vh;
}

.carousel-item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100%;
}

.carousel-item > img {
	width: 100vw;
	max-width: 100vw;
	max-height: 82vh;

	object-fit: cover; /* Ajusta el tamaño de la imagen mientras mantiene su proporción */
}

@media (max-width: 550px) {
	.carousel-container {
		width: 100%;
		height: 50vh!important; /* Ajusta la altura deseada para el carrusel */
		z-index: 1;
	}
}
