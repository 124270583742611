header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
.containerDashBoard {
	padding-top: 15vh;
	min-height: 92vh;
	text-align: center;
	overflow-y: hidden;
}
.containerDashBoard > h1 {
	font-size: 1.5rem;
	color: var(--text-color-claro);
	font-family: Nexa;
	font-weight: bold;
}
.fondoDashBoard {
	background-image: url("../../assets/fondos/FONDO1.png");
}
.tabsAdmin {
	background-color: var(--color-verde-footer);
}
.tabsAdmin li button {
	text-decoration: none;
	color: var(--text-color-claro);
	font-family: Montserrat !important;
}
.tabsAdmin li button {
	text-decoration: none;
	color: var(--text-color-claro);
}
.tabsAdmin li button:hover {
	color: var(--text-color-claro);
	font-weight: bold;
}
.tabsAdmin li button:active {
	color: var(--color-verde);
	font-weight: bold;
}
.tabsAdmin li button:focus {
	color: var(--color-verde);
	font-weight: bold;
}
.totalCompra {
	background-color: rgba(0, 128, 0, 0.26) !important;
	border-radius: 5px;
}
.tabAdmin {
	text-decoration: none;
	color: var(--text-color-claro);
}
.containerTablaProductos {
	display: flex;
	justify-content: space-between;
	background-color: white;
	width: 100%;
}

.buttonCrearProducto {
	border-radius: 10px;
	background-color: var(--color-verde);
	color: var(--text-color);
	border: none;
	padding: 5px 10px;
}
.containerInfo {
	background-color: var(--color-rosa-claro-trans);
	padding: 5vh 10vw;
	text-align: left;
}
.containerInfo h2 {
	color: var(--text-color-claro);
	font-family: Nexa;
	font-weight: bold;
	font-size: 1.5rem;
	text-align: center;
}
.containerInfo p {
	color: var(--text-color-claro);
	font-family: Montserrat !important;
	font-weight: bold;
}
.containerInfo p span,
ul li {
	color: var(--text-color-claro);
	font-family: Montserrat !important;
	font-weight: normal;
}

.searchDashboard {
	display: flex;
	justify-content: flex-start;
}
li {
	font-family: Montserrat !important;
}
.inputSearch {
	width: 300px;
	height: 2.5rem;
	padding-left: 30px !important;
}
.inputSearch:focus {
	outline: none !important;
	box-shadow: none !important;
	border-color: var(--color-verde) !important;
}
.form-control-feedback {
	position: absolute;
	padding: 10px;
	pointer-events: none;
}
.ajustarPreciosForm {
	height: auto;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}
.ajustarInputs {
	
	display: flex;
	flex-direction: column;
}

/* Estilos generales para dispositivos móviles */
@media (max-width: 768px) {
	/* Ajusta el estilo del contenedor principal */
	.containerDashBoard {
		margin-top: 25vh;
		padding: 20px;
	}

	/* Cambia el tamaño de fuente */
	h1 {
		font-size: 24px;
	}

	/* Cambia la disposición de los elementos */
	.tabsAdmin {
		flex-direction: row;
	}
	.containerTablaProductos {
		flex-direction: column;
	}
	/* .table {
		display: block;
		overflow-x: auto;
	}

	.table th,
	.table td {
		padding: 5px;
		font-size: 14px;
	} */
	.ajustarPreciosForm {
		width: 80%;
		display: flex;
		margin-left: 10vw;
		flex-direction: row;
	}
	.inputPorcentaje {
		width: 20vw;
	}
	.aplicarAjuste {
		width: 75%;
	}
}

/* Estilos específicos para pantallas aún más pequeñas */
@media (max-width: 480px) {
	/* Ajusta el tamaño de fuente aún más */
	h1 {
		font-size: 20px;
	}

	/* Reorganiza los elementos */
	.searchDashboard {
		margin: 10px auto;
		text-align: center;
	}
}
