header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
.mainContact {

	min-height: 83vh;
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow-y: hidden;
	justify-content: center;
	align-items: center;
}
.buttonTeam{
	transition: background-color 0.3s ease, transform 0.3s ease;
	border-radius:8px,
}

.buttonTeam:hover {
	background-color: #1e4d2b; /* Darker green on hover */
  transform: scale(1.05); /* Slightly increase the size on hover */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Deeper shadow on hover */
}

.scroll-indicator-contact {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 50px; /* Ajusta la altura según sea necesario */
  animation: bounceAnimationContact 1s infinite alternate;
}

@keyframes bounceAnimationContact {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

.fondoContact {
	background-image: url("../../assets/fondos/FONDO1.png");

	background-position: center;
}

.fade-in-contact {
  animation: fadeInAnimationContact 2s ease-in forwards;
  opacity: 0;
}

@keyframes fadeInAnimationContact {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.contactoContainer {
	/* min-height: 92vh; */
	margin-top: 3vh !important;
	text-align: center;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.546);
}
.containerForm > p {
	font-family: Nexa;
	font-weight: bold;
}
.contactoContainer2 {
	background-color: var(--color-salmon);
}
.contactoContainer2 > h2 {
	color: var(--text-color-bar);
}
.contactoMapa > iframe {
	width: 100%;
	height: 280px;
}
.containerListaRedes li i {
	color: var(--color-salmon);
	font-size: 2rem;
}
.containerListaRedes a:hover {
	cursor: pointer !important;
}
.contactoMedios > p {
	font-family: Montserrat;
	margin: 0;
}

@media (max-width: 426px) {
	.contactoMedios > p {
		font-size: 0.8rem;
	}
	.containerListaRedes li i {
		font-size: 1.5rem;
	}
}
