header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
main {
	min-height: 92vh;
	text-align: center;
	overflow-y: hidden;
}

.fondoDetail {
	background-image: url('../../assets/fondos/FONDOVERDE.jpg');
}

.containerDetail {
	width: 100vw !important;
	margin-top: 0;
	/* padding-top: 12vh !important; */
	/* padding-bottom: 3vh !important; */
	/* min-height: 92vh; */
	min-height: 92vh;

	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}
.containerDetail2 {
	display: flex;
	align-items: stretch;
	width: 100%;
	max-height: 500px;
	margin-right: 0 !important;
	justify-content: space-between;
	background-color: white;
}
.ofertasDetail {
	align-items: center;
	display: flex;
	max-width: 5%;
}
.veganDetail {
	margin-left: 2px;
	cursor: pointer;
	width: 30px;
	border-radius: 50%;
}
.new-sale-detail {

		margin-left: 2px;
		margin-bottom: 2px;
		/* max-width: 50px; */
		background-color: #ff61f4;
		color: #c1fe72;
	
	
}

.christmas-detail {

	margin-left: 2px;
	margin-bottom: 2px;
	width: 150px;

}
.containerForm {
	margin-top: 17vh !important;
	width: 100%;
}

.paddingSmall {
	padding-left: 2vh;
}
.aaaa {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1vh 0;
	margin-top: 2vh;
}
.eeee {
	margin: 0 !important;
}
.featurette span {
	color: var(--color-verde);
	font-weight: bold;
}
.detailFooter {
	width: 100%;
}
.linkDetail {
	text-decoration: none !important;
	width: 100%;
}
.carritoDetail {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100% !important;
	margin-top: 10px;
	padding: 6px 12px;
	border-radius: 10px;
	border: none;
	background-color: var(--color-rosa-claro);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	color: var(--text-color);
	font-weight: 600;
	font-size: 1.2rem;
}
.carritoDetail:hover {
	background-color: #e9bcbf;
}
.containerImg {
	display: flex;
	flex-direction: column;
	justify-content: center;
	object-fit: contain;
	/* width: 50%; */
	max-height: 500px;
	padding-right: 0;
}
.img-fluid {
	width: 100%;
	max-height: 500px;
	object-fit: cover;
	border-radius: 10px;
	background-position: right;
	min-width: 100%;
}

/* .col-md-7 {
	display: flex;
	flex-direction: column;
	justify-content: center;
} */
.containerDetail3 {
	display: flex;
	background-color: rgb(255, 255, 255);
	flex-direction: column;
	justify-content: space-between;
	/* width: 50%; */
	max-width: 25vw;
	padding: 10px 5px !important;
	border-radius: 10px;
}



.zoom-icon{
	cursor: pointer;

	margin-left: 800%;
	
	
}
.detailCarousel {
	width: 100%;
	height: 300px;
	object-fit: cover;
	z-index: 0;
}

.detailImage{
min-width:550px !important;
	min-height: 550px;
	max-height: 550px!important;
	
	object-fit: cover;
}
@media (max-width: 1100px) {
	.containerDetail{
		min-height: 93vh;
	}
	.containerForm {
		display: flex;
		width: 100vw;
		align-items: center !important;
		justify-content: center !important;
	}
	.containerDetail2 {
		display: flex;
		flex-direction: column;
		width: 100vw;
		align-items: center !important;
		justify-content: center !important;
		padding-left: 2vw;
	}
	/* .col-md-5,
	.col-md-7 {
		width: 100% !important;
	} */
	.containerDetail3 {
		max-width: 100%;
		width: 100% !important;
	}
	/* .containerDetail3 {
		width: 100% !important;
	} */
	.containerImg {
		display: flex;
		max-width: 100%;
		width: 100% !important;
		flex-direction: column-reverse;
	}
	/* .containerImg > img,
	.containerImg abbr button {
		width: 100%;
	} */
	.carritoDetail {
		margin: 0;
		margin-bottom: 10px;
	}
	.col-md-5 > h3 {
		text-align: center;
	}
	.img-fluid {
		height: 300px;
	}
}
@media (max-width: 550px) {
}
