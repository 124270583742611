.headerModalReset {
	background-color: var(--color-verde);
	color: var(--text-color);
}
.modalResetContainer {
	background-image: url('../../../assets/images/TOAST-FONDO.webp');
	/* background-color: var(--color-verde-footer); */
}
.divMagicoReset {
	display: flex;
	justify-content: center;
	align-items: center;
}
.buttonSecondary {
	font-weight: 600;
	font-size: 1.2rem;
	padding: 6px 12px;
	border-radius: 10px;
	border: none;
}
.botonModalConfirmacion {
	background-color: var(--color-rosa-claro);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	border: none;
	display: flex;
	align-items: center;
	color: var(--text-color);
	font-weight: 600;
	padding: 6px 12px;
	border-radius: 10px;
	font-size: 1.2rem;
	margin-left: 8px;
}
.botonModalConfirmacion:hover {
	background-color: #e9bcbf;
}
