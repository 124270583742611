* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: var(--text-color-claro);
}

:root {
	--color-fondo-claro: #fef9f3;
	--color-fondo-oscuro: #282828;
	--color-verde: #20563f;
	--color-verde-footer: rgba(32, 86, 63, 0.17);
	--color-rosa-claro: #e5c4c9;
	--color-rosa-trans: #e5c4c978;
	--color-rosa-super-clarito: #e9ced2;
	--color-rosa-claro-trans: #f4c6c950;
	--text-color: #fff;
	--text-color-claro: #565862;
	--text-color-navbar: #fff;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* background-image: url('../src/assets/images/FONDO_CORAZONES.png'); */
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-color: var(--color-fondo-claro);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* @media (max-width: 550px) {
	.body {
		background-repeat: repeat;
	}
} */
