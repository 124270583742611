.vencida-state {
	background-color: rgba(255, 0, 0, 0.607) !important;
	color: white !important;
}

.disponible-state {
	background-color: rgba(0, 128, 0, 0.591) !important;
	color: white !important;
}

.utilizada-state {
	background-color: rgba(0, 0, 255, 0.591) !important;
	color: white !important;
}

.parcial-state {
	background-color: rgba(255, 153, 0, 0.747) !important;
	color: white !important;
}
